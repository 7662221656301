:root{
    --title-color : #66fcf1;
    --background-color: #0c0d11;
    --paras : #c5c6c8;
    --cards : #46a29f;
    --font-style : 'Open Sans';
}
.about-section {
    margin-top: -30px;
    height: fit-content;
    padding-bottom: 30px;
    background-color: var(--background-color);
}
.about_content_section{
    flex: 1;
    display: flex;
    padding: 10px 40px;
    color: var(--paras);
    align-items: center;
    justify-content: space-around;
}
.about_content_section:nth-child(4) {
    margin-top: 50px;
}
.about-image{
    flex: 1;
    text-align: center;
}
.about-image img{
    width: 500px;
    border-radius: 20px;
    height: auto;
    box-shadow: 10px 0 85px -3px var(--cards);
}
.title_outer {
    width: 100%;
    padding-top: 90px;
    padding-bottom: 10px;
    text-align: center;
}
.section_title{
    cursor: default;
    color: var(--title-color);
    font-size: 35px;
    width: fit-content;
    height: fit-content;
    padding: 10px 15px;
    border-top: 1px solid var(--title-color);
    border-bottom: 1px solid var(--title-color);
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-stretch: 100%;
    transition: all .2s;
}
.section_title:hover {
    transform: scale(1.01);
    color: var(--background-color);
}
.section_title::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    color: white;
    z-index: -1;
    transform: scaleY(0);
    transition: transform 0.2s ease-in-out;
    transform-origin: 0 0;
    background-color: var(--title-color);
    box-shadow: 0 0 85px -10px var(--cards);
}
.section_title:hover::before {
    transform: scaleY(1);
}
.about-content,
.about-content_phone {
    width: 50%;
    margin-left: 3%;
}
.about-content p,
.about-content_phone p {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: none;
}
.about-content_phone {
    display: none;
}
.about-content h3 {
    color: white;
    font-size: 35px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: 'Open Sans';
    font-stretch: 100%
}

.row-2 {
    width: 90%;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
}

.py-40 {
    padding-top: 40px;
}

@media screen and (max-width: 920px) {
    
    .about_content_section {
        display: block;
    }
    .about-content {
        display: none;
    }
    .about-content_phone {
        width: 100%;
        margin-left: 0;
        display: block;
    }
    .about-content_phone p {
        width: 60%;
        text-align: center;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

}

@media screen and (max-width: 600px) {
    .about-image img {
        width: 100%;
    }

    .about-content_phone p {
        width: 100%;
    }

    .w-95-p {
        width: 95% !important;
    }
}