/* Courses.css */

.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 80px !important;
}

.skeleton-row {
  display: flex;
  justify-content: space-around;
}

.skeleton-item {
  width: 170px;
  height: 130px;
  background: linear-gradient(145deg, #686868 25%, #979696 50%, #686868 75%);
  background-size: 400% 100%; /* Double the width for smooth transition */
  border-radius: 8px;
  animation: skeleton-loading 2s infinite ease-in-out;
  margin: 15px 0;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
