.owner-section {
    width: 100%;
    padding-top: 100px;
}

.owner-detail {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--footer);
    box-shadow: 10px 0 85px -3px #262626;
}

.contact-header {
    display: flex;
    width: 95%;
    height: auto;
    padding: 20px 10px;
    margin-left: auto;
    margin-right: auto;
}

.ceo-img {
    min-width: 150px;
    max-width: 270px;
    height: auto;
    background-color: transparent;
    /* border: 1px solid red; */
}

.ceo-img img {
    width: 100%;
    border-radius: 20px;
    box-shadow: 5px 5px 85px -3px var(--footer);
}

.contact-detail {
    width: 76%;
    height: auto;
    margin-left: 3%;
    position: relative;
}

.contact-detail h2 {
    color: var(--title-color);
    font-family: 'IBM Plex Sans', sans-serif;
    /* font-family: 'Open Sans'; */
}

.ceo-tag {
    color: var(--title-hover);
    font-size: 15px;
    letter-spacing: 1px;
}

.contact-detail p {
    font-style: italic;
    color: var(--title-hover);
}

.contact-info {
    font-size: 14px;
    color: white;
}

.social-container {
    top: 5px;
    right: 0;
    padding: 0;
    width: 150px;
    height: 40.2px;
    overflow: hidden;
    position: absolute;
    border-right: 3px solid var(--title-color);
}

.social-container > a {
    width: fit-content;
}

.mx-10 {
    margin-left: 8px;
}

.mx-15 {
    margin-left: 8px;
}

.facebook,
.linkedin {
    width: 40px !important;
    height: 40px;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 7px;
    margin-left: 140px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.facebook:hover,
.linkedin:hover {
    cursor: pointer;
    margin-left: 110px;
}

.linkedin:hover {
    margin-left: 110px;
}

.facebook {
    background-color: #0866FF;
}

.linkedin {
    background-color: #0A66C2;
}

.desc-container {
    width: 100%;
    margin-top: 15px;
}

.desc-container p {
    color: var(--paras);
    font-style: normal;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (max-width: 920px) {

  .contact-header {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-detail {
    width: 100%;
    margin-top: 15px;
  }

}