.carousal-width {
    width: 700px;
    height: 250px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    border-radius: 20px;
    padding: 0 10px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border-left: 2px solid var(--title-color);
    border-right: 2px solid var(--title-color);
    background-color: transparent;
}

.carousal-width h5 {
    color: var(--title-color);
}
.carousal-width p {
    color: var(--paras);
}

.icons-colors {
    color: var(--title-color) !important;
}

.prev-review-btn,
.next-review-btn {
    width: 50px;
    color: var(--title-color);
}

.prev-review-btn {
    margin-left: -100px;
}
.next-review-btn {
    margin-right: -100px;
}

.carsoul-scroll-bars {
    position: absolute;
    margin-bottom: -50px;
}

/* carsoul bars */
.carsoul-scroll-bars [data-bs-target] {
    background-color: var(--title-color) !important;
}

.img-box {
    width: 100%;
    height: 100px;
    text-align: center;
    
}
.img-box img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    /* border: 1px solid green; */
}

.review-text {
    width: 100%;
    text-align: center;
}
.review-text p {
    padding: 0 10px;
    font-size: 15px;
}

.review-stars {
    width: fit-content;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    
}

.stars-color {
    margin: 0 3px;
    color: var(--title-color);
}

@media screen and (max-width: 600px) {

    .carousal-width {
        width: 95%;
    }

    .prev-review-btn,
    .next-review-btn {
        display: none;
    }
}