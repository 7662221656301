.banner-carsoul{
    position: relative;
    cursor: all-scroll;
}

.banner-inner-carsoul {
    height: 680px;
    user-select: none;
}

.banner-carsoulimg {
    width: 100%;
    height: 900px;
}

.slider_text {
    width: 100%;
    height: fit-content;
    margin-top: 18%;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.banner-img-p h2,
.slider_text h2,
.slider_text h1,
.slider_text p {
    width: 100%;
    color: var(--title-color);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Open Sans';
}

.banner-img-p h2,
.slider_text h2 {
    width: fit-content;
    height: fit-content;
    font-weight: 999;
    font-size: 90px;
    margin-left: auto;
    margin-right: auto;
    font-stretch: 100%
}

.slider_text h1 {
    width: fit-content;
    height: fit-content;
    font-weight: 999;
    font-size: 110px;
    margin-left: auto;
    margin-right: auto;
    font-stretch: 100%
}

.slider_text p {
    width: fit-content;
    height: fit-content;
    font-weight: 800;
    font-size: 16px;
    letter-spacing: 0;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
    font-stretch: 100%;
}

.prev-banner-btn {
    transform-origin: left;
}
.next-banner-btn {
    transform-origin: right;
}

.prev-banner-btn,
.next-banner-btn {
    width: 40px;
    height: 80px;
    margin-top: 280px;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
    background-color: var(--background-color);
}

.slide:hover .prev-banner-btn,
.slide:hover .next-banner-btn {
    transform: scaleX(1);
}

.banner-img-p {
  display: none;
}

@media only screen and (max-width: 900px) {
  .banner-inner-carsoul {
    height: 500px;
  }

  .slider_text {
    margin-top: 20%;
  }

  .slider_text h2{
    font-size: 60px;
  }

  .slider_text h1{
    font-size: 75px;
  }

  .slider_text p {
    font-size: 15px;
  }

  .prev-banner-btn,
  .next-banner-btn {
    margin-top: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .d-none-p {
    display: none;
  }

  .banner-img-p {
    display: block;
    position: relative;
  }

  .banner-img-p img {
    width: 100%;
  }

  .banner-img-p h2 {
    position: absolute;
    font-size: 25px;
    top: 40%;
    left: auto;
    right: auto;
    width: 100%;
    
  }
}