.accordion-flush {
    width: 650px;
    height: auto;
    margin: 20px auto 20px auto;
    border-bottom: 1px solid var(--cards);
}

.accordion-button {
    border-bottom: none;
}

.accordion-button,
.accordion-item {
    color: var(--cards);
    background-color: var(--background-color);
}

.accordion-item {
    margin: 0 0 5px 0;
    color: var(--paras);
    font-size: 15px;
    border-bottom: 1px solid var(--title-color);
}

.accordion-button:not(.collapsed) {
    color: var(--title-color);
    background-color: transparent;
}

.accordion-button:focus {
    box-shadow: none;
    border-bottom: var(--cards);
}