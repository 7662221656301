.partners-section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.partners-container {
    width: 70%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.partner-img {
    width: auto;
    height: 70px;
    margin: 10px 30px 10px 30px;
}

.img-circle {
    border-radius: 50%;
}


@media screen and (max-width: 600px) {

    .partner-img {
        height: 50px;
        margin: 10px 15px 10px 15px;
    }

}