.footer {
    height: 100px;
    width: 100%;
}

.copyright {
    width: 100%;
    color: var(--paras);
    text-align: center;
}

.copyright p {
    padding-top: 33px;
}