@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

:root {
  --title-color: #66fcf1;
  --title-hover: #58ccc8;
  --background-color: #0c0d11;
  --paras: #c5c6c8;
  --cards: #46a29f;
  --footer: #262626;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.menu-box {
    display: none;
}

.header {
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 999;
  transition: all .2s ease;
  justify-content: space-between;
  background-color: transparent;
}

.mobile_header_menu {
  display: none;
}

.navbar {
  user-select: none;
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 60px;
  height: 60px;
  transition: all .2s ease;
}

.logo .logoimg {
  height: 100%;
  user-select: none;
  border-radius: 50%;
  transition: all .2s ease;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list li a::after {
  content: "";
  width: 100%;
  height: 2px;
  margin-top: 3px;
  display: block;
  transform: scaleY(0);
  opacity: .8;
  /* transform-origin: right; */
  transition: transform 0.3s ease;
  background-color: var(--title-color);
}

.nav-list li a.active:after,
.nav-list li a:hover:after {
  transform: scaleY(1);
  /* transform-origin: left; */
}

.nav-list a {
  text-decoration: none;
  color: var(--title-color);
  font-size: 13px;
  font-family: "Open Sans";
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  transition: color 0.3s;
}

.btn-course {
  width: 100px;
  height: 35px;
  outline: none;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  color: var(--title-color);
  background-color: transparent;
  margin-top: -3px;
  margin-left: 5px;
  border: 2px solid var(--title-color);
  transition: all .2s;
}

.btn-course:hover {
  font-weight: 400;
  color: var(--background-color);
}

.btn-course::before {
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  color: white;
  z-index: -1;
  transform: scaleY(0);
  transition: transform 0.2s ease;
  transform-origin: bottom;
  background-color: var(--title-color);
  box-shadow: 0 0 85px -10px var(--cards);
}

.btn-course:hover::before {
  transform: scaleY(1);
}

.btn-phone-course {
  margin-top: 10px;
}

.coupon-container {
  width: 160px;
  height: 40px;
  margin-top: 3px;
  transition: all .3s;
}

.coupon {
  display: flex;
  flex-wrap: nowrap;
}

.name-card {
  width: 100%;
  height: 30px;
  padding-top: 6px;
  text-align: center;
  background-color: #000000CC;
}

.coupon-name {
  color: var(--title-hover);
  font-size: 14px;
}

.ceo-tag {
  width: 7%;
  height: 40px;
  cursor: pointer;
  margin-top: -5px;
  background-color: var(--footer);
}

.ceo-collapse {
  font-size: 14px;
  opacity: .5;
  line-height: 13px;
  text-align: center;
  justify-items: center;
  margin-top: -.5px;
}

.left-arrow {
  margin-top: 13px;
  margin-left: -2px;
  color: #66fcf279;
}

.f-13 {
  font-size: 13px;
}
.f-12 {
  font-size: 12px;
}

.mx-d-145 {
  margin-left: -145px;
}
.w-10p {
  width: 10%;
}

@media screen and (max-width: 920px) {
    body {
    width: 100%;
    /* overflow: hidden; */
  }
  .header {
    width: 100%;
  }
  .navbar {
    width: 100%;
  }
  .nav-list {
    display: none !important;
  }
  .mobile_header_menu {
    top: 0;
    right: 0;
    width: 25%;
    height: 100%;
    display: block;
    position: fixed;
    transform: scaleX(0);
    transform-origin: right;
    background-color: #262626;
    transition: transform 0.3s ease-in-out;
  }
  .mobile_sidebar_menu {
    list-style: none;
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .mobile_sidebar_menu li {
    display: block;
    width: 100%;
    margin: 2px 0;
    padding: 5px 0 7px 0;
  }
  .mobile_sidebar_menu li:hover {
    background-color: var(--background-color);
  }
  .mobile_sidebar_menu a {
    text-decoration: none;
    color: var(--title-color);
  }

  .cross_btn {
    width: fit-content;
    height: fit-content;
    font-weight: 800;
    cursor: pointer;
    margin-left: 20px;
    font-size: 30px;
    color: var(--paras);
  }

  .blury {
    background-color: transparent;
    backdrop-filter: none;
  }

  .menu-box {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 26px;
    top: 30px;
    right: 18px;
  }

  .menu-lines {
    width: 100%;
    height: 4px;
    background-color: var(--title-color);
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 600px) {
  .mobile_header_menu {
    width: 60%;
  }
  .logoimg {
    width: 45px !important;
  }
  .menu-box {
    top: 25px;
    right: 18px;
  }
  .logo .logoimg {
    height: auto;
  }
}