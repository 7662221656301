.courses {
    height: auto;
    padding-top: 50px;
    margin-bottom: 100px;
}

.course-contact-phone {
    padding: 0 100px 0 100px;
    color: var(--paras);
    text-align: right;
    font-size: 13px !important;
    margin-top: 10px;
}

.course-contact-phone strong {
    color: var(--title-color);
}

.course-contact-phone span {
    color: var(--title-color);
}

.row {
    width: 100%;
    padding: 20px 50px 10px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.course-container {
    width: 250px;
    height: 140px;
    text-align: center;
    margin: 10px;
}

.icon {
    font-size: 100px;
    color: var(--title-color);
}

.course-img {
    /* border: 1px solid red; */
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.course-name {
    font-size: 19px;
    margin-top: 10px;
    color: var(--title-hover);
}

.divider {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--title-color);
}

.my-10 {
    margin-top: 20px;
}